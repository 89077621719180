const headerLayerPosition = {
    setup : function() {
        this.marginTop = 0 ;
        this.$mobileMenu = $(".mobile-menu");
        this.$desktopMenu = $(".desktop-menu");
    },

    bindEvents:  function()  {
        this.setMarginTop();

        $(window).on('resize',function(){
            this.setMarginTop();
        }.bind(this));
    },

    setMarginTop: function (){
        if(isTouch()){
            this.marginTop = this.$mobileMenu.height();
            this.addInlineStyle (this.$mobileMenu, this.marginTop);
    
        } else{
            this.marginTop = this.$desktopMenu.find('.header__row').height();
            this.addInlineStyle (this.$desktopMenu, this.marginTop);
        }
    
        // $('.base-layout').css('margin-top',  this.marginTop);
    },

    addInlineStyle: function (device, marginTop) {
        device.find('.header_layer').css('height','calc(100% - ' + marginTop + 'px)');
    },

    init: function () {
        try {
            this.setup();
            this.bindEvents();

        }
        catch (e) {
            console.error(e);
        } 
    }

};

const manageHeaderLayers = { 
    setup : function() {
       
        this.$dataTogglers = $('.header-nav__actions [data-layertoggle]');
        this.$headerLayers = $('.header_layer');
        this.$btn_closeLayer = $('.header_btn-closeLayer');
        this.layer_activeClass = 'active';
        this.btn_activeClass = 'open';
        this.searchInput = isMobile() ? '.mobile-menu' : '.desktop-menu';
    },

    bindEvents:  function()  {
        this.$dataTogglers.on('click', function(evt){
            let $this =  $(evt.currentTarget);
            if ($this.hasClass('userLogged')){
                return
            }
            evt.preventDefault();
            let header_layer = $('.'+$this.data('layertoggle'));
            
            if (!header_layer.hasClass(this.layer_activeClass)) {
                this.closeLayers();
                this.openLayer($this, header_layer);
            } else {
                this.closeLayers();
            }

        }.bind(this));

        this.$btn_closeLayer.on('click', function(evt){
            this.closeLayers();
        }.bind(this));
    },

    closeLayers: function() {
        this.$headerLayers.removeClass(this.layer_activeClass);
        this.$dataTogglers.removeClass(this.btn_activeClass);

        document.querySelector('.menu-list.accordion').querySelectorAll("a").forEach(function (link) {
            link.setAttribute('tabindex', '-1')
        })
    },

    openLayer: function($this, header_layer) {
        //if new menu exist
        if(typeof sideMenu != "undefined"){
            sideMenu.close()
        }
        header_layer.addClass(this.layer_activeClass);
        $this.addClass(this.btn_activeClass);
        setTimeout(()=> {
            document.querySelector(`${this.searchInput} input.search-bar__input`).focus();
        },500);

        document.querySelector('.menu-list.accordion').querySelectorAll("a").forEach(function (link) {
            link.setAttribute('tabindex', '0')
        })
    },
  
    init: function () {
      try {
        this.setup();
        this.bindEvents();

      }
      catch (e) {
        console.error(e);
      } 
    }
  };


$(document).ready(function () {

    headerLayerPosition.init();
    manageHeaderLayers.init();

    $('a[href="#"]').click(function (event) {
        event.preventDefault();
    });
 
    let $menu = $('.menu-list');
    let $accordions = $menu.find('.accordion-toggle');
    $accordions.click(toggleAccordions);

    /**
     * This function toggles the content of an element with class 'accordion-toggle'.
     * It's execution context (aka 'this') is an element with class 'accordion-toggle'.
     * */
    function toggleAccordions() { 
        let $accordion = $(this);
        let isAlreadyOpen = $accordion.next('.accordion-content').hasClass('open');
        let $accordionContent = $accordion.next('.accordion-content');

        if (isAlreadyOpen) {
            $accordionContent.find('.accordion-content.open').toggleClass("open").slideToggle("fast");
            $accordionContent.toggleClass("open").slideToggle("fast");
            $accordion.children("a").toggleClass("active");

        } else {
            let $accordionParent = $($accordion.closest('.accordion-toggle').get(0));
            $accordionParent.siblings('.accordion-content.open').each(function (index, value) {
                $(value).toggleClass('open').slideToggle('fast');
                $(value).find('.accordion-content.open').toggleClass('open').slideToggle('fast');
                $(value).prev('.accordion-toggle').children('a').toggleClass("active");
            });

            $accordionContent.toggleClass("open").slideToggle("fast");
            $accordion.children("a").toggleClass("active");
        }
    }

    const burgerMenu = document.querySelectorAll("div.menu-wrapper.js-hamburger-menu.actions__link.actions__burger.d-m-none")

    burgerMenu.forEach(menu => {
        menu.addEventListener('keydown', function(event) {
            if (event.key === 'Enter') {
                if($('.burgerMenu').hasClass("active")){
                    manageHeaderLayers.closeLayers()
                }else{
                    manageHeaderLayers.openLayer($(menu), $('.burgerMenu'))
                }
            }
        });
    })
 
});
